import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addBlog } from '../blogsActions';
import "./share.css";
import ReactQuill from 'react-quill';
import { uploadImage } from '../../../../assets/lib/utils/uploadImage';
import { convertFileToUrl, generateUniqueFileName } from '../../../../assets/lib/utils/utility';


const ShareForm = (props) => {
  // console.log("props: ", props);
  const [showOptions, setShowOptions] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [imagePath, setImagePath] = useState('');
  const [formKey, setFormKey] = useState(0);
  const initialBlogsState = {
    title: '',
    desc: '',
    timestamp: new Date(),
    author: {
        id: props.userId,
        name: props.user ? props.user.name : 'anonymous',
        profileImage: props.user?.profileImage || ''
    },
    photo: '',
    likes: 0,
    comments: 0,
    isNew: true,
    isApproved: false,
    tier: "visitor", /* (visitor, inmate, admin) */
  };
  const [blogsState, setBlogsState] = useState(initialBlogsState);
  const [descError, setDescError] = useState(false);
  const formRef = useRef(null); // Ref for the form

  useEffect(() => {
  if (props.user && props.user.name) {
    setBlogsState(prevState => ({
      ...prevState,
      author: {
        id: props.userId,
        name: props.user.name,
        profileImage: props.user.profileImage || ''
      }
    }));
  }
}, [props.user]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (formRef.current && !formRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    // Add click event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [formRef]);

  const handleDescriptionChange = (value) => {
    setBlogsState({ ...blogsState, desc: value });
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setBlogsState({ ...blogsState, photo: file });
      setImagePath(convertFileToUrl(file));
    } else {
      setBlogsState({ ...blogsState, photo: null });
      setImagePath('');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!blogsState.desc || blogsState.desc.trim() === '<p><br></p>') {
      setDescError(true);
      return;
    }

    setDescError(false);
    
    const user = props.user;
    if (user) {
        if (blogsState.photo) {
          uploadImage(
            "blogImages",
            `${generateUniqueFileName(props.user.name, blogsState.photo)}`,
            blogsState.photo,
            (err, url) => {
              if (url) {
                const payload = {
                  ...blogsState,
                  photo: url,
                };
                props.addBlog(payload);
              }
            }
          )
        } else {
          console.log("No image to upload, blogState: ", blogsState);
          props.addBlog(blogsState);
        }
        // Display success message and reset form
        setSubmissionSuccess(true);
        // Inside handleSubmit, after successful submission
        setBlogsState({
          ...initialBlogsState,
          author: {
            id: props.userId,
            name: props.user ? props.user.name : 'anonymous',
            profileImage: props.user?.profileImage || ''
          },
          timestamp: new Date(), // Reset timestamp to current time
        });
        setFormKey(prevKey => prevKey + 1); // Increment formKey to reset file input
        setImagePath('');
        // Optionally, hide the success message after a few seconds
        setTimeout(() => setSubmissionSuccess(false), 8000);
    }
    else {
        alert("Please Log In to Create Blog")
    }
  };

  const handleTitleFocus = () => {
    setShowOptions(true);
  };

  return (
    <form ref={formRef} className="shareWrapper" onSubmit={handleSubmit}>
      <h2>Share Your Blog</h2>
      <input
        placeholder="Title"
        className="shareInput"
        name="title"
        value={blogsState.title}
        onChange={(e) => setBlogsState({ ...blogsState, title: e.target.value })}
        onFocus={handleTitleFocus}
        required
      />
      {showOptions && (
        <div>
          <h5>Description:</h5>
          <ReactQuill
            theme="snow"
            value={blogsState.desc}
            onChange={handleDescriptionChange}
            style={{ backgroundColor: 'white' }} /* Set background to white */
          />
          <h5>Add a Image:</h5>
          <div style={{ height: imagePath ? "150px" : "40px" }}>
            <input
              key={formKey}
              type="file"
              name="photo"
              onChange={handleImageChange}
              className="shareOption"
              accept="image/*"
            />
            {imagePath && <img alt="Image Preview" src={imagePath} style={{ height: '100px' }} />}
          </div>
          {descError && <div className="errorMsg">Description is required.</div>}
        </div>
      )}
      <button className="shareButton" type="submit">Share</button>
      {submissionSuccess && <div className="successMsg">Thank you! Your Submission received. It will be visible post admin-approval.</div>}
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
      user: state.profileSection.user,
      userId: state.auth.userId,
  }
}

const mapDispatchToProps = (dispatch) => ({
  addBlog: bindActionCreators(addBlog, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareForm);